.ant-modal-close {
  display: none;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
}
